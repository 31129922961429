.modal1 {
     position: fixed;
     top: 0;
     bottom: 0;
     left: 0;
     right: 0;
     background: rgba(0,0,0, 0.3);
     z-index: 99;
}

.modal1 .container {
     background-color:rgb(182, 206, 179);
     position: fixed;
     max-width: 400px;
     height: 257px;
     top: 7%;
     left: 0;
     right: 0;
     margin: 0 auto;
}

.label {
     margin-top: 20px;
}

.label1 {
     margin-top: 20px;
}