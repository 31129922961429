span.brasil:before {
     float: left;
     margin-left: 400px;
     content: url(./brasil.png);
 }

 span.espanha:before {
     float: left;
     margin-left: 80px;
     content: url(./espanha.png);
 }


.foto {
    width: 100%;
 }

