#btn {
     color: blue;
     border-radius: 5px;
}


.ficha {
    width: 300px;
}
 
.treeviewmed {
     margin-left: 5px;
     margin-top: 25px;
}

#txtArea1 {
     max-width: 100%;
}


.GridContainer{
     display: flex;
     width: 75%;
}

@media only screen and (max-width: 576px) {
          #limpar {
               margin-left: 170px;
          }
          .grupo {
               width: 75%;
          }
     
          .sub-grupo {
               width: 75%;
          }
     
          .tratamento {
               width: 75%;
          }
     
     .footer {
        font-size: 12px;
         width: 100%;
     }
 }