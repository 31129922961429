.float-right {
     margin-left: 5px;
}

.texto-livre {
     height: 200px;
}

.motivo {
     margin-left: 15px;
     width: 350px;
}

select {
     margin-top: -8px;
}

.btn {
     margin-top: -8px;
}

.buttons{
     margin-left: 200px;
}

.sair {
     background-color: red;
}

#txtArea {
     margin-left: 18px;
}

@media only screen and (max-width: 576px) {
     #limpar {
          margin-left: 170px;
     }
     #grupoPrincipal{
          width: 75%;
     }


     #subGrupoPrincipal {
          width: 75%;
     }

     #tratamento {
          width: 75%;
     }

     .footer {
        font-size: 12px;
         width: 100%;
     }
 }