footer.footer {
     display: flex;
     align-items: center;
     justify-content: center;
     box-shadow: var(--shadow);
}


/*Celular*/
@media only screen and (max-width: 576px) {
     .footer {
        font-size: 12px;
         width: 100%;
     }
 }
 