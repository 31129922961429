header .item{
     display: flex;
     flex-direction: row;
     width: 100%;
     flex-wrap: wrap;
     justify-content: space-between;
     align-items: center;
     margin-top: 10px;
 }
 
 header.header {
     background-color: #fff;
     padding: 2px 15px;
 
     white-space: nowrap;
     box-shadow: var(--shadow);
     width: 100%;
 }
 
 header.header h1 {
    font-size: 1.8em;
    margin-left: 50px;
 }
 
 .imagemDeFundo{
     max-width: 100%;
     max-height: 100%;
     align-content: center;
 }
 
label {
     font-style: italic;
     font-weight: bold;
}


@media only screen and (max-width:576px) {
    header.header h1 {
        font-size: 1.2em;
    }
    
}