#limpar{
     margin-top: 65px;
     margin-left: 190px;
}

.grupo {
     width: 65%;
}

.sub-grupo {
     width: 65%;
}

.tratamento {
     width: 65%;
}

@media (max-width: 576px) {
     #limpar {
          margin-left: 170px;
     }
     .grupo {
          width: 75%;
     }

     .sub-grupo {
          width: 75%;
     }

     .tratamento {
          width: 75%;
     }
}