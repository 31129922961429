:root {
     --bg-dark: #1a2f3a;
     --logo-height: 100px;
     --header-heigh: 100px;
     --aside-width: 225px;
     --footer-height: 40px;

     --shadow:
     0 2px 23px 0 rgba(0, 0, 0, 0.1),
     0 2px 49px 0 rgba(0, 0, 0, 0.06);
}

* {
     box-sizing: border-box;
     font-family: 'Montserrat', sans-serif;
}

#app {
     margin: 0px;
     display: grid;
     grid-template-columns: var(--aside-width) 1fr;
     grid-template-rows:
          var(--header-heigh)
          1fr
          var(--footer-height);
     grid-template-areas: 
          "logo header"
          "menu content"
          "footer footer";
     height: 100vh;
     background-color: #f5f5f5;

}

#app.hide-menu {
     margin: 0px;
     display: grid;
     grid-template-columns: 1fr;
     grid-template-rows: var(--header-height) 1fr var(--footer-height);
     grid-template-areas:
       "header header"
       "content content"
       "footer footer";
   }

aside.logo {
     grid-area: logo;
     width: 100%;
     margin-left: -16px;
     margin-right: 0px;
     background-size: cover;
}

header.header {
     grid-area: header;
}

aside.menu-area {
     grid-area: menu;
}

main.content {
     grid-area: content;
}

footer.footer {
     grid-area: footer;
}

@media(max-width: 768px) {
     #app{
         grid-template-rows: 
             var(--header-height)
             var(--menu-top-height)
             1fr
             var(--footer-height);
         grid-template-columns: var(--aside-width) 1fr;
         grid-template-areas: 
             "logo header"
             "menu menu"
             "content content"
             "footer footer";
     }
   }

@media(max-width: 576px) {
     :root {
       --logo-height: 65px;
       --menu-top-height: 51px; 
       --header-height: 51px;
     }
     #app{
         grid-template-rows: 
             var(--logo-height)
             var(--menu-top-height)
             1fr
             var(--footer-height);
         grid-template-columns: 1fr;
         grid-template-areas: 
             "logo"
             "menu header"
             "content"
             "footer";
     }
   }