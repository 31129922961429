.modal1 {
     position: fixed;
     top: 0;
     bottom: 0;
     left: 0;
     right: 0;
     background: rgba(0,0,0, 0.3);
     z-index: 99;
}

.modal1 .container {
     background-color:lightgray;
     position: fixed;
     max-width: 400px;
     height: 110px;
     top: 15%;
     left: 0;
     right: 0;
     margin: 0 auto;
}

.clinica {
     width: 200px;
}